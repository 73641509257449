
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import router from "@/router";
import CursoService from "@/services/admin/CursoService";
import DivisaoService from "@/services/auth/DivisaoService";
import ContaService from "@/services/conta/ContaService";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const turmas = ref<Turma[]>();
    const cursos = ref<Curso[]>();
    const tipoAvaliacaoId = route.params.idTipoAvaliacao;
    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    const getCursos = (): void => {
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          });
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
      }
    };

    const getMyCursos = (): void => {
      ContaService.get()
        .then((res) => {
          cursos.value = res.data.cursos;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar disciplinas",
          });
        });
    };

    onMounted(() => {
      if (store.getters.userPerfil == 'administrador') {
        getCursos();
      } else {
        getMyCursos();
      }
    });

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return { turmas, goTo, store, tipoAvaliacaoId, cursos };
  },
});
